import headerHtml from './Header.html';
import './Header.scss';
// libraries
import 'hamburgers/_sass/hamburgers/hamburgers.scss';

const HeaderSection = (container, navigation, activeLink) => {
    container.innerHTML += headerHtml;

    window.addEventListener('DOMContentLoaded', () => {
        /* show navigation menu */
        const headerMenuBtn = document.querySelector('.header__menu-btn');
        const headerNav = document.querySelector('.header__links');

        let menuActive = false;

        const toggleNav = () => {
            if (menuActive) {
                menuActive = false;
                document.body.classList.remove('no-scroll');
                headerMenuBtn.classList.remove('is-active');
                headerNav.classList.remove('nav-active');
            } else {
                menuActive = true;
                document.body.classList.add('no-scroll');
                headerMenuBtn.classList.add('is-active');
                headerNav.classList.add('nav-active');
            }
        };

        headerMenuBtn.addEventListener('click', toggleNav);

        /* submenu navigation */
        const submenuWrapper = document.querySelector('.header__menu-select');
        const submenuLinks = document.querySelectorAll('.header__menu-option .header__menu-link');
        const submenuActiveTab = document.querySelector('.header__menu-select-active > .header__menu-link');

        // keep dropdown shown
        submenuLinks.forEach((link) => {
            link.addEventListener('focus', () => {
                submenuWrapper.classList.add('header__menu-select-show');
            });

            // hide dropdown
            link.addEventListener('blur', () => {
                submenuWrapper.classList.remove('header__menu-select-show');
            });
        });

        // prevent clicking on the current active link
        submenuActiveTab.addEventListener('click', (event) => {
            event.preventDefault();
        });

        /* pages navigation */
        const navValues = Object.entries(navigation);

        const pagesNavLink = document.querySelectorAll('[data-navigation]');
        // set active styles for a link, that matches current page
        pagesNavLink[activeLink].classList.add('header__menu-link-currentPage');

        pagesNavLink.forEach((link) => {
            const linkAddress = link.getAttribute('data-navigation');
            const linkHref = link.getAttribute('href');

            navValues.map((navlink) => {
                if (linkAddress === navlink[0] && linkHref === '#') {
                    link.setAttribute('href', navlink[1]);
                }
            });
        });

        /* multilang functionality */
        const multilangLinks = document.querySelectorAll('[data-selectLanguage]');

        multilangLinks.forEach((link) => {
            let pathToLang = '';

            const linkAddress = link.getAttribute('data-selectLanguage');

            pathToLang = navigation.multilangPath + linkAddress;

            link.setAttribute('href', pathToLang);
        });
    });
};

export { HeaderSection };

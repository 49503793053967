import anchorHtml from './AnchorToTop.html';
import './AnchorToTop.scss';
// libraries
import animateScrollTo from 'animated-scroll-to';

const AnchorToTop = (container) => {
    container.innerHTML += anchorHtml;

    window.addEventListener('DOMContentLoaded', () => {
        const anchor = document.querySelector('.anchorToTop');

        anchor.addEventListener('click', () => {
            animateScrollTo(0);
        });
    });
};

export { AnchorToTop };

import introHtml from './Intro.html';
import './Intro.scss';

const IntroSection = (container) => {
    container.innerHTML += introHtml;

    window.addEventListener('DOMContentLoaded', () => {
        const introInner = document.querySelector('.intro__inner');
        const header = document.querySelector('.header');

        /* set padding top for the 'intro' section */
        const setPaddingTop = () => {
            /* get header height */
            const headerHeight = header.clientHeight;

            introInner.style.paddingTop = `${headerHeight}px`;
        };

        setPaddingTop();

        window.addEventListener('scroll', setPaddingTop);

        /* play cubes animation when images load */
        const introCubeImage = document.querySelectorAll('.intro__image img');

        introCubeImage.forEach((img) => {
            img.addEventListener('load', () => {
                img.parentNode.classList.add('intro__cube-loaded');
            });
        });
    });
};

export { IntroSection };

// libraries
import 'core-js/actual';
import 'overlayscrollbars/overlayscrollbars.css';
import { OverlayScrollbars } from 'overlayscrollbars';
// styles
import './styles/styles.scss';
import './fonts/fonts.scss';
// sections
import { HeaderSection } from 'Sections/Header/Header';
import { IntroSection } from 'Sections/Intro/Intro';
import { DeFiCoreSection } from 'Sections/DeFiCore/DeFiCore';
import { AccountsSection } from 'Sections/Accounts/Accounts';
import { EcosystemSection } from 'Sections/Ecosystem/Ecosystem';
import { HomeAccordionSection } from 'Sections/HomeAccordion/HomeAccordion';
import { EnterpriseSection } from 'Sections/Enterprise/Enterprise';
import { FooterSection } from 'Sections/Footer/Footer';
// components
import LazyLoad from 'Components/LazyLoad/LazyLoad';
import { AnchorToTop } from 'Components/AnchorToTop/AnchorToTop';

const bodyContainer = document.body;

const mainContainer = (container) => {
    // root container
    let mainTag = document.createElement('main');
    mainTag.className = 'main';
    // sections
    IntroSection(mainTag);
    DeFiCoreSection(mainTag);
    AccountsSection(mainTag);
    EcosystemSection(mainTag);
    HomeAccordionSection(mainTag);
    EnterpriseSection(mainTag);

    return container.appendChild(mainTag);
};

const navigation = {
    home: './index.html',
    solutions: './pages/solutions.html',
    developers: './pages/developers.html',
    contact: './pages/contact.html',
    multilangPath: '../',
};

// sections
HeaderSection(bodyContainer, navigation, 0);
mainContainer(bodyContainer);
FooterSection(bodyContainer);
// components
LazyLoad();
AnchorToTop(bodyContainer);

window.addEventListener('DOMContentLoaded', () => {
    /* use a custom scrollbar but only on desktop devices */
    if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        const osInstance = OverlayScrollbars(document.querySelector('body'), {});
    }
});

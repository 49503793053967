// Imports
import ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ from "../../../node_modules/html-loader/dist/runtime/getUrl.js";
var ___HTML_LOADER_IMPORT_0___ = new URL("../../images/LOGO.png", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("./header-img/earth-icon.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_2___ = new URL("../../images/icons/arrow-down.svg", import.meta.url);
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACEMENT_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACEMENT_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var code = "<header class=\"header\"> <div class=\"container\"> <div class=\"header__inner\"> <a href=\"#\" class=\"header__logo\" data-navigation=\"home\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" alt=\"logotype\"> </a> <nav class=\"header__menu\"> <button class=\"header__menu-btn hamburger hamburger--spring\" type=\"button\"> <span class=\"hamburger-box\"> <span class=\"hamburger-inner\"></span> </span> </button> <ul class=\"header__links\"> <li> <ul class=\"header__links-group\"> <li class=\"header__links-item\"> <a href=\"#\" class=\"header__menu-link btn btn-primary\" data-navigation=\"solutions\"> Solutions </a> </li> <li class=\"header__links-item\"> <a href=\"#\" class=\"header__menu-link btn btn-primary\" data-navigation=\"developers\"> Developers </a> </li> </ul> </li> <li> <ul class=\"header__links-group\"> <li class=\"header__links-item\"> <ul class=\"header__menu-select\" tabindex=\"0\"> <li class=\"header__menu-select-active\"> <a href=\"#\" class=\"header__menu-link btn btn-primary\" tabindex=\"-1\"> <img src=\"" + ___HTML_LOADER_REPLACEMENT_1___ + "\" id=\"header__earth-icon\"> English <img src=\"" + ___HTML_LOADER_REPLACEMENT_2___ + "\" id=\"header__arrow-down\"> </a> </li> <li class=\"header__menu-option\"> <a href=\"\" class=\"header__menu-link btn btn-primary\" data-selectLanguage=\"es/index.html\"> Español </a> </li> </ul> </li> <li class=\"header__links-item\"> <a href=\"https://console.orangepill.technology/\" target=\"blank\" class=\"header__menu-link btn btn-secondary header__menu-link-login\"> Login </a> </li> <li class=\"header__links-item\"> <a href=\"#\" class=\"header__menu-link btn btn-secondary\" data-navigation=\"contact\"> Get in touch </a> </li> </ul> </li> </ul> </nav> </div> </div> </header>";
// Exports
export default code;
import accountsHtml from './Accounts.html';
import './Accounts.scss';

const AccountsSection = (container) => {
    container.innerHTML += accountsHtml;

    window.addEventListener('DOMContentLoaded', () => {
        /* typing effect for json values */
        const jsonFields = document.querySelectorAll('.accounts__json-highlight');

        let jsonFieldsText = {};
        // get object values from the markup
        jsonFields.forEach((field) => {
            const fieldText = field.innerHTML;
            const fieldId = field.getAttribute('id');
            jsonFieldsText = { ...jsonFieldsText, [fieldId]: fieldText };
            // remove object values from the markup
            field.innerHTML = '';
        });

        // insert object value to a single string
        const insertJsonValue = (item) => {
            return new Promise((resolve, reject) => {
                // get item info
                const itemId = item.getAttribute('id');
                const textToInsert = jsonFieldsText[itemId];

                // current letter
                let currLetter = 0;

                // text typing effect
                let textTyping = setInterval(() => {
                    item.innerHTML += textToInsert.substring(currLetter, currLetter + 1);
                    currLetter++;

                    // stop typing
                    if (currLetter > textToInsert.length) {
                        clearInterval(textTyping);
                        resolve(true);
                    }
                }, 40);
            });
        };

        // insert all strings to the page sequently
        const drawAllJsonValues = () => {
            insertJsonValue(jsonFields[0])
                .then(() => insertJsonValue(jsonFields[1]))
                .then(() => insertJsonValue(jsonFields[2]))
                .then(() => insertJsonValue(jsonFields[3]))
                .then(() => insertJsonValue(jsonFields[4]))
                .then(() => insertJsonValue(jsonFields[5]))
                .then(() => insertJsonValue(jsonFields[6]));
        };

        // play the effect when the json object appears at user's screen
        if ('IntersectionObserver' in window) {
            const observerCallback = (entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        drawAllJsonValues();
                        observer.unobserve(entry.target);
                    }
                });
            };

            const observerOptions = {
                threshold: 0.5,
            };

            const observer = new IntersectionObserver(observerCallback, observerOptions);

            observer.observe(document.querySelector('.accounts__json'));
        }
    });
};

export { AccountsSection };

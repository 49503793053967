import homeAccordionHtml from './HomeAccordion.html';
import './HomeAccordion.scss';

const HomeAccordionSection = (container) => {
    container.innerHTML += homeAccordionHtml;

    window.addEventListener('DOMContentLoaded', () => {
        // first item
        const homeAccordionItems = document.querySelectorAll('.homeAccordion__item');
        // list of all items
        const homeAccordionActiveItem = document.querySelector('.homeAccordion__item-active');

        const showActiveItem = (item) => {
            item.classList.add('homeAccordion__item-active');

            const titleHeight = item.querySelector('.homeAccordion__item-top').clientHeight;
            const textHeight = item.querySelector('.homeAccordion__item-textWrapper').clientHeight;

            item.style.height = `${titleHeight + textHeight}px`;
        };

        const hideItems = (item) => {
            item.classList.remove('homeAccordion__item-active');

            const titleHeight = item.querySelector('.homeAccordion__item-top').clientHeight;

            item.style.height = `${titleHeight}px`;
        };

        homeAccordionItems.forEach((item) => {
            // set height for the unactive items
            hideItems(item);

            // add onclick function to show the item
            item.addEventListener('click', () => {
                homeAccordionItems.forEach((itemsToHide) => {
                    hideItems(itemsToHide);
                });

                showActiveItem(item);
            });
        });

        // set height for the first item
        showActiveItem(homeAccordionActiveItem);
    });
};

export { HomeAccordionSection };
